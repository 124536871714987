import Typography from "typography"
import Theme from "typography-theme-moraga"

Theme.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
    "ul, ol": {
      marginLeft: `1.75rem`,
    },
    "h1,h2,h3,h4,h5,h6": {
      marginTop: `1.75rem`,
    },
    "h1 + h2, h2 + h3, h3 + h4, h4 + h5, h5 + h6": {
      marginTop: `0`,
    }
  }
}

delete Theme.googleFonts

const typography = new Typography(Theme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
