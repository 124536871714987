import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import { rhythm } from "../../../utils/typography"

class TagIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges
    const tags = {}
    posts.forEach(({ node }) => {
      (node.frontmatter.tags || []).forEach((tag) => {
        tags[tag] = (tags[tag] || 0) + 1
      })
    })

    // TODO Sort
    const tagLinks = Object.keys(tags).map((tag) => {
      return (
        <div>
          <Link to={`/blog/tags/${tag}`}>
            {tag}
          </Link>
        </div>
      )
    })

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Wilson Mitchell's blog by subject"
          keywords={Object.keys(tags)}
        />
        <h2 style={{ marginTop: rhythm(1.0) }}>Blog Posts By Subject</h2>
        {tagLinks}
      </Layout>
    )
  }
}

export default TagIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            tags
          }
        }
      }
    }
  }
`
