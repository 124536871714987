import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"
import "./nav.css"

class Layout extends React.Component {
  render() {
    const { title, children } = this.props
    const navItems = [
      { href: `/blog/`, label: `Blog` },
      { href: `/projects/`, label: `Projects` },
      { href: `/about/`, label: `About` },
      { href: `/contact/`, label: `Contact` }
    ];
    const navElems = navItems.map((navItem) => {
      return (
        <Link
          key={navItem.label}
          to={navItem.href}
          activeClassName={`active`}
          className={`nav-item`}
          partiallyActive={true}>
            {navItem.label}
        </Link>
      )
    })
    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(30),
          padding: `${rhythm(1.0)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>
          <h1
            style={{
              ...scale(1.25),
              marginBottom: rhythm(0.5),
              marginTop: 0,
            }}
          >
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
                fontWeight: 400,
              }}
              to={`/`}
            >
              {title}
            </Link>
          </h1>
          <nav
            style={{
              backgroundColor: `#000000`,
              ...scale(0.5),
              marginBottom: rhythm(1.0),
              display: `flex`,
              flexWrap: `wrap`,
            }}>
	    {navElems}
          </nav>
        </header>
        <main>{children}</main>
        <footer style={{
          marginTop: rhythm(1.0),
          marginBottom: rhythm(1.0),
        }}>
          <hr style={{
            marginTop: rhythm(0.5),
            marginBottom: rhythm(0.5),
          }}/>
          © {new Date().getFullYear()}
        </footer>
      </div>
    )
  }
}

export default Layout
